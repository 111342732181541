<template>
  <Box>

    <Menu page="file" />
    
    <TitleRow>
      File
    </TitleRow>

    <Row>
      <Column>
        <Value> File </Value>
      </Column>
    </Row>
    
    <SpacerRow />
  </Box>
</template>
<script>
import { mapGetters } from 'vuex'

import ConstUtils   from "@/utils/ConstUtils.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

import Value from "@/portals/shared/library/value/Value.vue";

import TitleRow from '@/portals/shared/library/title/TitleRow.vue';
import SpacerRow from "@/portals/shared/library/spacer/SpacerRow.vue";

import Menu  from './Menu.vue';

export default {
  name: "portal-customer-operator-employee-file",
  components: {
    Box,Row,Column, TitleRow,
    Value, SpacerRow,
    Menu,
  },
  data() {
    return {
      id: this.$route.params["id"],
    };
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'domain',
                ]),
    userData: function() {
      return this.user.data;
    },
    user: function() {
      return this.employee.user().find();
    },
    
    employeeData: function() {
      return this.employee.data;
    },
    employee: function() {
      return this.domain.employees().findById(this.id);
    },
  },
  watch: {
    
  },
  mounted: function() {
    if (!this.auth_connected) {
      this.$router.replace({ name: this.$route.query.redirectTo || ConstUtils.ROUTES.SIGNIN, });
    }
  },
  methods: {
    
  },
}
</script>